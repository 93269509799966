<template>
  <v-card>
    <v-toolbar dark class="primary">
      <v-btn icon dark @click.native="close">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>Buldit filters order</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn dark flat @click.native="save">
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout row>
      <v-flex xs6 ma-5>
        <v-card>
          <v-card-text>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Buldit priority filters</v-toolbar-title>
            </v-toolbar>
            <table-data-provider
              ref="tablePriority"
              :url="urlPriority"
              :pagination="paginationPriority"
              :filters="filtersPriority"
              :includes="includes"
              :entity-name="entityName"
              :schema="schema"
            >
              <v-data-table
                ref="sortableTablePriority"
                slot-scope="props"
                :headers="headers"
                :items="props.items"
                :pagination.sync="paginationPriority"
                :total-items="props.totalItems"
                :rows-per-page-items="[1000]"
                disable-initial-sort
                disable-page-reset
                :loading="props.isLoading"
                class="elevation-1"
              >
                <template slot="items" slot-scope="props">
                  <tr :key="itemKey(props.item)" class="sortableRow">
                    <td class="px-1" style="width: 0.1%">
                      <v-btn style="cursor: move" icon class="sortHandle">
                        <v-icon>drag_handle</v-icon>
                      </v-btn>
                    </td>
                    <td>{{ props.item.sortOrder }}</td>
                    <td>{{ props.item.name }}</td>
                  </tr>
                </template>
              </v-data-table>
            </table-data-provider>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs6 ma-5>
        <v-card>
          <v-card-text>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Buldit non-priority filters</v-toolbar-title>
            </v-toolbar>
            <table-data-provider
              ref="tableNonPriority"
              :url="urlNonPriority"
              :pagination="paginationNonPriority"
              :filters="filtersNonPriority"
              :includes="includes"
              :entity-name="entityName"
              :schema="schema"
            >
              <v-data-table
                ref="sortableTableNonPriority"
                slot-scope="props"
                :headers="headers"
                :items="props.items"
                :pagination.sync="paginationNonPriority"
                :total-items="props.totalItems"
                :rows-per-page-items="[1000]"
                disable-initial-sort
                disable-page-reset
                :loading="props.isLoading"
                class="elevation-1"
              >
                <template slot="items" slot-scope="props">
                  <tr :key="itemKey(props.item)" class="sortableRow">
                    <td class="px-1" style="width: 0.1%">
                      <v-btn style="cursor: move" icon class="sortHandle">
                        <v-icon>drag_handle</v-icon>
                      </v-btn>
                    </td>
                    <td>{{ props.item.sortOrder }}</td>
                    <td>{{ props.item.name }}</td>
                  </tr>
                </template>
              </v-data-table>
            </table-data-provider>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script type="text/babel">
import Sortable from "sortablejs";
import { cloneDeep, isEqual } from "lodash";
import { filter as schema } from "@/store/schema";
import TableDataProvider from "@/components/legacy/generic/data/TableDataProvider";

export default {
  name: "filter-order-view",
  components: {
    TableDataProvider
  },
  props: {
    internalDialog: {
      type: Boolean
    }
  },
  data: () => ({
    headers: [
      {
        sortable: false
      },
      { text: "order", align: "left", value: "sortOrder", sortable: false },
      { text: "name", align: "left", value: "name", sortable: false }
    ],
    originalClonedPriorityBulditFilters: null,
    originalClonedNonPriorityBulditFilters: null,
    updatedPriorityFilters: [],
    updatedNonPriorityFilters: [],
    originalPriorityFilters: [],
    originalNonPriorityFilters: [],
    filtersToUpdate: [],
    filter: {
      id: null,
      sortOrder: null
    },
    itemKeys: new WeakMap(),
    currentItemKey: 0,
    priorityFilters: [],
    nonPriorityFilters: [],
    paginationPriority: {
      sortBy: "sort_order",
      descending: false
    },
    urlPriority: "api/v1/filters",
    filtersPriority: {
      priority: true,
      type: "BULDIT"
    },
    paginationNonPriority: {
      sortBy: "sort_order",
      descending: false
    },
    urlNonPriority: "api/v1/filters",
    filtersNonPriority: {
      priority: false,
      type: "BULDIT"
    },
    includes: [],
    entityName: "filter"
  }),
  computed: {
    schema() {
      return schema;
    }
  },
  mounted() {
    new Sortable(
      this.$refs.sortableTablePriority.$el.getElementsByTagName("tbody")[0],
      {
        draggable: ".sortableRow",
        handle: ".sortHandle",
        onEnd: this.dragReorderPriority
      }
    );
    new Sortable(
      this.$refs.sortableTableNonPriority.$el.getElementsByTagName("tbody")[0],
      {
        draggable: ".sortableRow",
        handle: ".sortHandle",
        onEnd: this.dragReorderNonPriority
      }
    );
    this.$watch(
      () => {
        return this.$refs.tablePriority.internalItems;
      },
      val => {
        this.priorityFilters = [];
        this.originalClonedPriorityBulditFilters = cloneDeep(val);
        this.originalClonedPriorityBulditFilters.forEach(e => {
          this.priorityFilters.push(e);
        });
      }
    );
    this.$watch(
      () => {
        return this.$refs.tableNonPriority.internalItems;
      },
      val => {
        this.nonPriorityFilters = [];
        this.originalClonedNonPriorityBulditFilters = cloneDeep(val);
        this.originalClonedNonPriorityBulditFilters.forEach(e => {
          this.nonPriorityFilters.push(e);
        });
      }
    );
  },
  methods: {
    dragReorderPriority({ oldIndex, newIndex }) {
      this.updatedPriorityFilters = [];
      const movedItem = this.priorityFilters.splice(oldIndex, 1)[0];
      this.priorityFilters.splice(newIndex, 0, movedItem);
      this.priorityFilters.forEach((e, index) => {
        e.sortOrder = index + 1;
        this.updatedPriorityFilters.push(e);
      });
    },
    dragReorderNonPriority({ oldIndex, newIndex }) {
      this.updatedNonPriorityFilters = [];
      const movedItem = this.nonPriorityFilters.splice(oldIndex, 1)[0];
      this.nonPriorityFilters.splice(newIndex, 0, movedItem);
      this.nonPriorityFilters.forEach((e, index) => {
        e.sortOrder = index + 1;
        this.updatedNonPriorityFilters.push(e);
      });
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    async save() {
      this.updatedPriorityFilters.forEach((e, index) => {
        if (!isEqual(e, this.$refs.tablePriority.internalItems[index])) {
          let filter = { id: e.id, sortOrder: e.sortOrder };
          this.filtersToUpdate.push(filter);
        }
      });
      this.updatedNonPriorityFilters.forEach((e, index) => {
        if (!isEqual(e, this.$refs.tableNonPriority.internalItems[index])) {
          let filter = { id: e.id, sortOrder: e.sortOrder };
          this.filtersToUpdate.push(filter);
        }
      });
      await this.$store.dispatch("filters/updateFilterSortOrder", {
        filters: this.filtersToUpdate
      });
      this.filtersToUpdate = [];
      this.updatedPriorityFilters = [];
      this.updatedNonPriorityFilters = [];
      this.$emit("close-dialog");
    },
    close() {
      this.$emit("close-dialog");
    }
  }
};
</script>
