<template>
  <v-card color="white">
    <crud-header
      :title="titleString"
      type="Filter"
      store="filter"
      :color="!isActive ? 'grey' : paused ? 'warning' : 'primary'"
      icon="far fa-filter"
      :savable="!isSnapshot"
      :revertable="!isSnapshot"
      :deletable="!isSnapshot"
      @save-success="() => $router.push({ name: 'filters' })"
      @delete-success="() => $router.push({ name: 'filters' })"
    >
      <v-btn
        v-if="!isSnapshot"
        slot="toolbarActions"
        icon
        :disabled="isLoading || !logs.length"
        @click="logDialog = true"
      >
        <v-icon>far fa-history</v-icon>
      </v-btn>
    </crud-header>
    <v-dialog v-model="logDialog" max-width="1600">
      <template v-if="logDialog">
        <v-card ma-0>
          <v-toolbar color="light-blue" class="primary" dense>
            <v-toolbar-title class="white--text">
              Older versions
            </v-toolbar-title>
          </v-toolbar>
          <company-filter-logs :currentFilterId="id" :filterLogs="logs" />
        </v-card>
      </template>
    </v-dialog>
    <company-filter
      :id="id"
      :loading="isLoading"
      selectable-type
      @exit="$router.push({ name: 'filters' })"
      @save="save"
    />
  </v-card>
</template>

<script type="text/babel">
import { isNil } from "lodash";
import CompanyFilter from "@/components/filter/Filter";
import filterModule from "@/store/modules/filter";
import { mapDetailViewLifecycleMethods } from "@/views/helpers";
import { createNamespacedHelpers } from "vuex";
import { SAVE } from "@/store/templates/crud/action-types";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import CompanyFilterLogs from "@/components/company/filter-logs/CompanyFilterLogs";
import moment from "moment";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";

const { mapGetters } = createNamespacedHelpers("filter");
const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "filter",
  filterModule
);

export default {
  name: "filter-view",
  mixins: [IsValidationRoot],
  components: {
    CompanyFilterLogs,
    CrudHeader,
    CompanyFilter
  },
  data() {
    return {
      isLoading: false,
      logDialog: false
    };
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    companyId: Number
  },

  metaInfo() {
    return {
      title: `Filter - ${isNil(this.name) ? this.id : this.name}`
    };
  },

  computed: {
    ...mapGetters([
      "isSnapshot",
      "currentVersionId",
      "name",
      "logs",
      "isSnapshot",
      "isPaused",
      "pausedTill",
      "isActive",
      "paused"
    ]),
    titleString() {
      let string = this.name;
      if (!this.isActive) {
        string += " (inactive)";
      }
      if (this.paused) {
        if (this.pausedTill) {
          string += ` (Paused until ${moment(this.pausedTill).format(
            "DD MMM YYYY"
          )})`;
        } else {
          string += ` (Paused indefinitely)`;
        }
      }
      return string;
    }
  },
  methods: {
    async save() {
      this.isLoading = true;
      const filterId = await this.$store.dispatch(`filter/${SAVE}`);
      this.isLoading = false;
      this.$router.push({ name: "filters.show", params: { filterId } });
    }
  },
  watch: {
    id() {
      this.logDialog = false;
    }
  },
  ...detailViewLifecycleMethods
};
</script>
