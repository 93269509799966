<template>
  <tr>
    <td>{{ filterLog.title }}</td>
    <td>{{ filterLog.description }}</td>
    <td>{{ fromUTC }}</td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'filters.show',
          params: { filterId: filterLog.targetableId }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { FIND_BY_ID } from "../../../store/templates/table/getter-types";

export default {
  name: "company-filter-log-table-row",
  props: {
    filterLogId: {
      type: Number,
      required: true
    }
  },
  computed: {
    filterLog() {
      return this.$store.getters[`company/filterLogs/${FIND_BY_ID}`](
        this.filterLogId
      );
    },
    fromUTC() {
      const utcDate = this.filterLog.occurredAt;
      return new Date(
        `${utcDate.slice(0, 10)}T${utcDate.slice(11, 19)}Z`
      ).toLocaleTimeString("nl", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      });
    }
  }
};
</script>
