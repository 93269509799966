<template>
  <div>
    <table-overview
      title="Filters"
      store="filters"
      :headers="[
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Remark', value: 'remark', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Pedigree', value: 'type', sortable: false },
        { text: 'Company', value: 'company', sortable: false },
        { text: 'Country', value: 'country', sortable: false },
        { text: 'Categories', value: 'categories', sortable: false },
        { text: 'Limits', value: 'currentWeeklyLeads', sortable: false },
        { text: 'Auto-Deliver', value: 'isAutoDelivery', sortable: false },
        {
          text: 'Actions',
          align: 'right',
          value: 'name',
          sortable: false,
          width: '100px'
        }
      ]"
      searchable
      force-initial-sort="sort_order"
      descending
    >
      <filter-table-row
        slot="table-row"
        slot-scope="{ item }"
        :filter-id="item"
      />
      <filters-table-filter slot="filters" store="filters" />
    </table-overview>
    <filters-order-dialog :order="order" @close="order = false" />
    <v-fab-transition>
      <v-speed-dial
        v-show="true"
        :top="false"
        :bottom="true"
        :right="true"
        :left="false"
        fixed
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <v-btn slot="activator" v-model="fab" color="blue darken-2" dark fab>
          <v-icon>more_vert</v-icon>
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn fab dark small color="pink" :to="{ name: 'filters.create' }">
          <v-icon>far fa-fw fa-plus</v-icon>
        </v-btn>
        <v-btn fab dark small color="pink" @click="order = true">
          <v-icon>swap_vert</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-fab-transition>
  </div>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import filtersModule from "@/store/modules/filters";
import FilterTableRow from "../../components/filter/FiltersTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";
import FiltersTableFilter from "@/components/filter/FiltersTableFilter";
import FiltersOrderDialog from "@/views/filters/FiltersOrderDialog";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "filters",
  filtersModule
);

export default {
  name: "filters-view",
  components: {
    FiltersOrderDialog,
    FiltersTableFilter,
    FilterTableRow,
    TableOverview
  },
  data: () => ({
    order: false,
    fab: false
  }),
  computed: {
    actions() {
      return [
        {
          icon: "far fa-fw fa-plus",
          color: "pink",
          to: { name: "filters.create" },
          rolesOrPermissions: "filters_create",
          clickFn: null
        },
        { icon: "swap_vert", color: "pink", clickFn: this.orderFnc() }
      ];
    }
  },
  methods: {
    orderFnc() {
      this.order = true;
    }
  },
  ...overviewLifecycleMethods
};
</script>
