<template>
  <v-form>
    <v-select v-model="type" :items="types" label="Type" clearable />
    <v-autocomplete-category v-model="categories" multiple />
    <v-autocomplete-country v-model="countries" multiple />
  </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteCountry from "../country/VAutocompleteCountry";
import VAutocompleteCategory from "../category/VAutocompleteCategory";

const { mapComputedFilters } = createNamespacedHelpers("filters");

export default {
  name: "filters-table-filters",
  components: {
    VAutocompleteCategory,
    VAutocompleteCountry
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    types() {
      return [
        {
          text: "Company Filter",
          value: "COMPANY"
        },
        {
          text: "Buldit Filter",
          value: "BULDIT"
        }
      ];
    },
    ...mapComputedFilters(["type", "categories", "countries"])
  }
};
</script>
