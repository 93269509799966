<template>
  <tr
    :class="{
      'grey lighten-3': !filter.isActive,
      'warning lighten-3':
        filter.isActive &&
        (filter.paused ||
          (filter.type === 'COMPANY' && company.state === 'PAUSED'))
    }"
  >
    <td>
      {{ filter.name }}
      <small v-if="!filter.isActive"> (inactive)</small>
      <small v-if="filter.paused"> (paused)</small>
    </td>
    <td class="remark">
      <v-tooltip bottom>
        <span slot="activator"> {{ filter.remark }} </span>{{ filter.remark }}
      </v-tooltip>
    </td>
    <td>
      <v-chip
        label
        small
        :color="isFilterCompanyType ? 'info' : 'accent'"
        :dark="isFilterCompanyType"
      >
        {{ filter.isPriority ? `${filter.type}-PRIORITY` : filter.type }}
      </v-chip>
    </td>
    <td>
      <v-chip
        v-for="(relation, index) in pedigree"
        :key="index"
        label
        small
        :color="!isFilterCompanyType ? 'info' : 'accent'"
        :dark="!isFilterCompanyType"
      >
        {{ `${relation.filter.name} ${relation.percentage}%` }}
      </v-chip>
    </td>
    <td v-if="filter.type === 'COMPANY'">
      {{ company.name }}
      <small v-if="company.state === 'PAUSED'"> (paused)</small>
    </td>
    <td v-else>
      <v-chip
        v-for="({ companyName, percentage },
        index) in bulditFilterCompaniesWithPercentages"
        :key="index"
        label
        small
      >
        {{
          `${companyName}${percentage !== null ? `&nbsp;${percentage}%` : ""}`
        }}
      </v-chip>
    </td>
    <td>{{ country.name }}</td>
    <td>
      <template v-for="(category, index) in filterCategories">
        <v-chip v-if="index < 2" :key="index" label small>
          {{ category.name.nl_be }}
        </v-chip>
      </template>
      <v-tooltip v-if="filterCategories.length > 2" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <v-chip
            v-for="(category, index) in filterCategories"
            :key="index"
            label
          >
            {{ category.name.nl_be }}
          </v-chip>
        </span>
      </v-tooltip>
    </td>
    <td>
      <span style="float: right">
        {{
          `${filter.currentWeeklyLeads}&nbsp;/&nbsp;${
            filter.maxWeeklyLeads === null ? "&infin;" : filter.maxWeeklyLeads
          }`
        }}
      </span>
    </td>
    <td>
      <v-icon>{{
        filter.isAutoDelivery ? "far fa-check" : "far fa-times"
      }}</v-icon>
    </td>
    <td>
      <v-btn
        icon
        :to="{ name: 'filters.show', params: { filterId: filter.id } }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapFilterGetters,
  mapState: mapFilterState
} = createNamespacedHelpers("filters");

export default {
  name: "filter-table-row",
  props: {
    filterId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapFilterGetters([FIND_BY_ID]),
    ...mapFilterState(["filter"]),
    filter() {
      return this[FIND_BY_ID](this.filterId);
    },
    isFilterCompanyType() {
      return this.filter.type === "COMPANY";
    },
    isFilterBulditType() {
      return this.filter.type === "BULDIT";
    },
    company() {
      return this.$store.getters["filters/company"](this.filterId);
    },
    filterCategories() {
      return this.$store.getters["filters/filterCategories"](this.filterId);
    },
    country() {
      return this.$store.getters["filters/country"](this.filterId);
    },
    bulditFilterCompaniesWithPercentages() {
      return this.$store.getters[
        "filters/bulditFilterCompaniesWithPercentages"
      ](this.filterId);
    },
    pedigree() {
      return this.$store.getters["filters/pedigree"](this.filterId);
    }
  }
};
</script>
<style scoped>
.remark {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}
</style>
