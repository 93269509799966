var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:{
    'grey lighten-3': !_vm.filter.isActive,
    'warning lighten-3':
      _vm.filter.isActive &&
      (_vm.filter.paused ||
        (_vm.filter.type === 'COMPANY' && _vm.company.state === 'PAUSED'))
  }},[_c('td',[_vm._v(" "+_vm._s(_vm.filter.name)+" "),(!_vm.filter.isActive)?_c('small',[_vm._v(" (inactive)")]):_vm._e(),(_vm.filter.paused)?_c('small',[_vm._v(" (paused)")]):_vm._e()]),_c('td',{staticClass:"remark"},[_c('v-tooltip',{attrs:{"bottom":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" "+_vm._s(_vm.filter.remark)+" ")]),_vm._v(_vm._s(_vm.filter.remark)+" ")])],1),_c('td',[_c('v-chip',{attrs:{"label":"","small":"","color":_vm.isFilterCompanyType ? 'info' : 'accent',"dark":_vm.isFilterCompanyType}},[_vm._v(" "+_vm._s(_vm.filter.isPriority ? ((_vm.filter.type) + "-PRIORITY") : _vm.filter.type)+" ")])],1),_c('td',_vm._l((_vm.pedigree),function(relation,index){return _c('v-chip',{key:index,attrs:{"label":"","small":"","color":!_vm.isFilterCompanyType ? 'info' : 'accent',"dark":!_vm.isFilterCompanyType}},[_vm._v(" "+_vm._s(((relation.filter.name) + " " + (relation.percentage) + "%"))+" ")])}),1),(_vm.filter.type === 'COMPANY')?_c('td',[_vm._v(" "+_vm._s(_vm.company.name)+" "),(_vm.company.state === 'PAUSED')?_c('small',[_vm._v(" (paused)")]):_vm._e()]):_c('td',_vm._l((_vm.bulditFilterCompaniesWithPercentages),function(ref,index){
  var companyName = ref.companyName;
  var percentage = ref.percentage;
return _c('v-chip',{key:index,attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(("" + companyName + (percentage !== null ? (" " + percentage + "%") : "")))+" ")])}),1),_c('td',[_vm._v(_vm._s(_vm.country.name))]),_c('td',[_vm._l((_vm.filterCategories),function(category,index){return [(index < 2)?_c('v-chip',{key:index,attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(category.name.nl_be)+" ")]):_vm._e()]}),(_vm.filterCategories.length > 2)?_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-chip',{attrs:{"slot":"activator"},domProps:{"innerHTML":_vm._s('...')},slot:"activator"}),_c('span',_vm._l((_vm.filterCategories),function(category,index){return _c('v-chip',{key:index,attrs:{"label":""}},[_vm._v(" "+_vm._s(category.name.nl_be)+" ")])}),1)],1):_vm._e()],2),_c('td',[_c('span',{staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(((_vm.filter.currentWeeklyLeads) + " / " + (_vm.filter.maxWeeklyLeads === null ? "∞" : _vm.filter.maxWeeklyLeads)))+" ")])]),_c('td',[_c('v-icon',[_vm._v(_vm._s(_vm.filter.isAutoDelivery ? "far fa-check" : "far fa-times"))])],1),_c('td',[_c('v-btn',{attrs:{"icon":"","to":{ name: 'filters.show', params: { filterId: _vm.filter.id } }}},[_c('v-icon',[_vm._v("far fa-search")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }