import filterApi from "@/api/filter";
import questionApi from "@/api/question";
import categoryApi from "@/api/category";
import crudActions from "@/store/templates/crud/actions";
import { difference } from "lodash";

export default {
  ...crudActions(filterApi, "filter"),
  async fetchAlwaysVisibleQuestions(context) {
    const { entities, result } = await questionApi.fetchAll({
      filters: { alwaysVisible: true },
      includes: ["label", "options.label"]
    });
    context.commit("setAlwaysVisibleQuestions", { entities, result });
  },
  async loadCategoryQuestions(context, { categoryId }) {
    const { entities, result } = await categoryApi.fetch(categoryId, {
      includes: [
        "categorySubjects.filterQuestionOptions",
        "categorySubjects.subject.questions.label",
        "categorySubjects.subject.questions.options.label",
        "categorySubjects.subject.questions.options.parentOption.label",
        "categorySubjects.subject.questions.options.parentOption.parentOption.label",
        "categorySubjects.subject.questions.options.parentOption.parentOption.parentOption.label",
        "categorySubjects.subject.questions.parentQuestion.label",
        "categorySubjects.subject.questions.parentQuestion.parentQuestion.label",
        "categorySubjects.subject.questions.parentQuestion.parentQuestion.parentQuestion.label"
      ]
    });
    context.commit("setCategoryQuestions", { categoryId: result, entities });
  },
  addCategory(context, { categoryId }) {
    const category =
      context.rootState["category-select"].entities.category[categoryId];
    context.commit("addCategory", { category });
  },
  addSubFilter({ commit, rootState }, filterId) {
    const filter = rootState["filter-select"].entities.filter[filterId];
    const company = rootState["filter-select"].entities.company[filter.company];
    commit("addSubFilter", { filter, company });
  },
  setCountry({ commit, dispatch }, country) {
    commit("setCountry", country);
    commit("clearGeography");
    dispatch("geo/fetchCountry", country, { root: true });
  },
  setCategories({ state, commit, rootState }, categories) {
    const oldCategories = state.model.filterCategories.map(
      filterCategory => filterCategory.category
    );
    const toAdd = difference(categories, oldCategories);
    const toRemove = difference(oldCategories, categories);

    toAdd.forEach(categoryId => {
      const category =
        rootState["category-select"].entities.category[categoryId];
      commit("addCategory", { category });
    });

    toRemove.forEach(categoryId => {
      commit("removeCategory", { categoryId });
    });
  }
};
