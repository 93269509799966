<template>
  <v-data-table
    :headers="[
      { text: 'Title', value: 'title' },
      { text: 'Description', value: 'description' },
      { text: 'Occurred at', value: 'occurredAt' },
      {
        text: 'Details',
        sortable: false,
        value: 'details',
        align: 'center',
        width: '100px'
      }
    ]"
    :items="filterLogs"
    :pagination.sync="pagination"
  >
    <company-filter-log-table-row
      slot="items"
      slot-scope="props"
      v-bind="props"
      name="table-row"
      :filter-log="props.item"
      :class="{ currentFilter: props.item.targetableId === currentFilterId }"
    />
  </v-data-table>
</template>
<script type="text/babel">
import CompanyFilterLogTableRow from "@/components/company/filter-logs/CompanyFilterLogTableRow";

export default {
  name: "company-filter-logs",
  components: { CompanyFilterLogTableRow },
  data: () => ({
    pagination: {
      descending: true,
      sortBy: "occurredAt"
    }
  }),
  props: {
    filterLogs: null,
    currentFilterId: null
  }
};
</script>
<style scoped lang="scss">
.currentFilter {
  background-color: var(--v-primary-lighten5);
}
</style>
