<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Filter"
      store="filter"
      icon="far fa-filter"
      :savable="!isSnapshot"
      :revertable="!isSnapshot"
      @save-success="() => $router.push({ name: 'filters' })"
    />
    <company-filter
      :loading="isLoading"
      selectable-type
      @exit="$router.push({ name: 'filters' })"
      @save="save"
    />
  </v-card>
</template>

<script type="text/babel">
import CompanyFilter from "../../components/filter/Filter";
import { mapCreateViewLifecycleMethods } from "@/views/helpers";
import { createNamespacedHelpers } from "vuex";
import filterModule from "@/store/modules/filter";
import { SAVE } from "@/store/templates/crud/action-types";
import CrudHeader from "@/components/generic/crud/CrudHeader";

const { mapGetters } = createNamespacedHelpers("filter");
const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "filter",
  filterModule
);

export default {
  name: "filter-create-view",
  components: { CrudHeader, CompanyFilter },
  props: {
    companyId: Number
  },
  ...createViewLifecycleMethods,
  data: () => ({
    isLoading: false
  }),
  metaInfo() {
    return {
      title: "Create filter"
    };
  },
  computed: {
    ...mapGetters(["isSnapshot", "name"])
  },
  methods: {
    async save() {
      this.isLoading = true;
      const filterId = await this.$store.dispatch(`filter/${SAVE}`);
      this.isLoading = false;
      this.$router.push({ name: "filters.show", params: { filterId } });
    }
  }
};
</script>
