var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":[
    { text: 'Title', value: 'title' },
    { text: 'Description', value: 'description' },
    { text: 'Occurred at', value: 'occurredAt' },
    {
      text: 'Details',
      sortable: false,
      value: 'details',
      align: 'center',
      width: '100px'
    }
  ],"items":_vm.filterLogs,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('company-filter-log-table-row',_vm._b({class:{ currentFilter: props.item.targetableId === _vm.currentFilterId },attrs:{"name":"table-row","filter-log":props.item}},'company-filter-log-table-row',props,false))}}])})}
var staticRenderFns = []

export { render, staticRenderFns }