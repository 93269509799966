<template>
  <v-dialog
    v-model="internalDialog"
    fullscreen
    transition="dialog-bottom-transition"
    lazy
  >
    <filter-order-view @close-dialog="internalDialog = false" />
  </v-dialog>
</template>

<script type="text/babel">
import FilterOrderView from "./FilterOrderView";

export default {
  name: "filters-order-dialog",
  components: {
    FilterOrderView
  },
  props: {
    order: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalDialog: {
      get() {
        return this.order;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>
