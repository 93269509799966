var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-overview',{attrs:{"title":"Filters","store":"filters","headers":[
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Remark', value: 'remark', sortable: false },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Pedigree', value: 'type', sortable: false },
      { text: 'Company', value: 'company', sortable: false },
      { text: 'Country', value: 'country', sortable: false },
      { text: 'Categories', value: 'categories', sortable: false },
      { text: 'Limits', value: 'currentWeeklyLeads', sortable: false },
      { text: 'Auto-Deliver', value: 'isAutoDelivery', sortable: false },
      {
        text: 'Actions',
        align: 'right',
        value: 'name',
        sortable: false,
        width: '100px'
      }
    ],"searchable":"","force-initial-sort":"sort_order","descending":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('filter-table-row',{attrs:{"filter-id":item}})}}])},[_c('filters-table-filter',{attrs:{"slot":"filters","store":"filters"},slot:"filters"})],1),_c('filters-order-dialog',{attrs:{"order":_vm.order},on:{"close":function($event){_vm.order = false}}}),_c('v-fab-transition',[_c('v-speed-dial',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"top":false,"bottom":true,"right":true,"left":false,"fixed":"","direction":"top","transition":"slide-y-reverse-transition"}},[_c('v-btn',{attrs:{"slot":"activator","color":"blue darken-2","dark":"","fab":""},slot:"activator",model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-icon',[_vm._v("more_vert")]),_c('v-icon',[_vm._v("close")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"pink","to":{ name: 'filters.create' }}},[_c('v-icon',[_vm._v("far fa-fw fa-plus")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"pink"},on:{"click":function($event){_vm.order = true}}},[_c('v-icon',[_vm._v("swap_vert")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }